import type { Licence } from '../models/types'

const GUID = '093A49A9-4EF5-4D1D-9BC4-102361C87844'
const defaultLicence: Licence = {
  Customer: {
    Name: 'DAVI SAS'
  },
  Vendor: {
    Name: 'DAVI SAS'
  },
  Licences: [
    {
      productid: '093A49A9-4EF5-4D1D-9BC4-102361C87844',
      expireAt: '2026-02-18'
    }
  ]
}

export { GUID, defaultLicence }
