const fetchSpeechServicesToken = async (
  region: string,
  key: string
): Promise<any | undefined> => {
  const tokenRes = await fetch(
    `https://${region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
    {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': key
      }
    }
  )
    .then((value) => {
      return value.text()
    })
    .catch((error) => {
      console.log('Error : ', error)
      return undefined
    })

  return tokenRes
}

export { fetchSpeechServicesToken }
